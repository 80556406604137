import React, { useState, useEffect } from "react";
import TableCell from "@material-ui/core/TableCell";
//
import "../../css/tablecell.css";

export default function CustomTableCell(props) {
  const [color, setColor] = useState(props.defaultColor);
  const onClick1 = () => {
    if (color == "#ffc710") {
      setColor("transparent");
    } else {
      setColor("#ffc710");
    }
  };

  return (
    <TableCell
      onClick={() => onClick1()}
      style={{ backgroundColor: color, border: "solid 1px gray" }}
    >
      {props.children}
    </TableCell>
  );
}
