import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
//
import "../../css/tablecalc.css";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 650
  }
}));

const tableHead = {
  fontWeight: "bold"
};

function createData(col1, col2) {
  return { col1, col2 };
}

export default function DenseTable(props) {
  const classes = useStyles();

  const rows = [
    createData(
      <input
        style={({ textAlign: "left" }, tableHead)}
        className="calcinput"
        label=""
        name="produtodescricao"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtodescricao === ""
            ? "PRODUTOS"
            : props.item.produtodescricao
        }
      />,
      <input
        style={tableHead}
        className="calcinput"
        label=""
        name="produtomaterial"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtomaterial === ""
            ? "QUANTIDADE"
            : props.item.produtomaterial
        }
      />
    ),
    createData(
      <input
        style={{ textAlign: "left" }}
        className="calcinput"
        label=""
        name="produtodescricao0"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtodescricao0 === ""
            ? "Staubli conector MC4 acoplador fêmea"
            : props.item.produtodescricao0
        }
      />,
      <input
        className="calcinput"
        label=""
        name="produtomaterial0"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtomaterial0 === "" ? "" : props.item.produtomaterial0
        }
      />
    ),
    createData(
      <input
        style={{ textAlign: "left" }}
        className="calcinput"
        label=""
        name="produtodescricao1"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtodescricao1 === ""
            ? "Staubli conector MC4 acoplador macho"
            : props.item.produtodescricao1
        }
      />,
      <input
        
        className="calcinput"
        label=""
        name="produtomaterial1"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtomaterial1 === "" ? "" : props.item.produtomaterial1
        }
      />
    ),
    createData(
      <input
        style={{ textAlign: "left" }}
        
        className="calcinput"
        label=""
        name="produtodescricao2"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtodescricao2 === ""
            ? "String Box ProAuto quadro 4 entradas/2saídas"
            : props.item.produtodescricao12
        }
      />,
      <input
        
        className="calcinput"
        label=""
        name="produtomaterial2"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtomaterial2 === "" ? "" : props.item.produtomaterial2
        }
      />
    ),
    createData(
      <input
        style={{ textAlign: "left" }}
        
        className="calcinput"
        label=""
        name="produtodescricao3"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtodescricao3 === ""
            ? "Inversores solar REFUSOL 7.5Kw Monofásico 220V"
            : props.item.produtodescricao3
        }
      />,
      <input
        
        className="calcinput"
        label=""
        name="produtomaterial3"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtomaterial3 === "" ? "" : props.item.produtomaterial3
        }
      />
    ),
    createData(
      <input
        style={{ textAlign: "left" }}
        
        className="calcinput"
        label=""
        name="produtodescricao4"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtodescricao4 === ""
            ? "Painéis solares BYD 335W Policristalino 17% eficiência"
            : props.item.produtodescricao4
        }
      />,
      <input
        
        className="calcinput"
        label=""
        name="produtomaterial4"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtomaterial4 === "" ? "" : props.item.produtomaterial4
        }
      />
    ),
    createData(
      <input
        style={{ textAlign: "left" }}
        
        className="calcinput"
        label=""
        name="produtodescricao5"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtodescricao5 === ""
            ? "Estruturas ROMAGNOLE perfil de alumínio 4,15m/ 4 painéis"
            : props.item.produtodescricao5
        }
      />,
      <input
        
        className="calcinput"
        label=""
        name="produtomaterial5"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtomaterial5 === null
            ? ""
            : props.item.produtomaterial5
        }
      />
    ),
    createData(
      <input
        style={{ textAlign: "left" }}
        
        className="calcinput"
        label=""
        name="produtodescricao6"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtodescricao6 === ""
            ? "Estruturas ROMAGNOLE painéis fixadores Z"
            : props.item.produtodescricao6
        }
      />,
      <input
        
        className="calcinput"
        label=""
        name="produtomaterial6"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtomaterial6 === null
            ? ""
            : props.item.produtomaterial6
        }
      />
    ),
    createData(
      <input
        style={{ textAlign: "left" }}
        
        className="calcinput"
        label=""
        name="produtodescricao7"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtodescricao7 === ""
            ? "Cabos Nexans Energyflex 0,6/1KV 1500V DC Preto"
            : props.item.produtodescricao7
        }
      />,
      <input
        
        className="calcinput"
        label=""
        name="produtomaterial7"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtomaterial7 === null
            ? ""
            : props.item.produtomaterial7
        }
      />
    ),
    createData(
      <input
        style={{ textAlign: "left" }}
        
        className="calcinput"
        label=""
        name="produtodescricao8"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtodescricao8 === ""
            ? "Cabos Nexans Energyflex 0,6/1KV 1500V DC Vermelho"
            : props.item.produtodescricao8
        }
      />,
      <input
        
        className="calcinput"
        label=""
        name="produtomaterial8"
        onChange={e => props.onChange(e)}
        value={
          props.item.produtomaterial8 === null
            ? ""
            : props.item.produtomaterial8
        }
      />
    )
  ];

  const table = {
    backgroundColor: "#fff"
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Table className={classes.table} size="small" style={table}>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.col1}>
                <TableCell component="th" scope="row">
                  {row.col1}
                </TableCell>
                <TableCell align="center">{row.col2}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
