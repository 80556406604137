import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "./TableCell";
import TableCellDefault from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Textfield from "./Textfield";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 650
  }
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  {
    col1: "",
    col2: "",
    col3: "",
    col4: "",
    col5: "",
    col6: "",
    col7: "",
    col8: "",
    col9: "",
    col10: ""
  }
];

export default function DenseTable(props) {
  const classes = useStyles();

  const table = {
    backgroundColor: "#fff"
  };

  const [state, setState] = useState({ ...props.item });
  const onChange = e => {
    props.onChange(e);
    setState({ ...state, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (props.item) {
      setState({
        ...props.item
      });
    }
  }, []);
  const [color, setColor] = useState("transparent");
  const onClick1 = () => {
    if (color == "lightgreen") {
      setColor("transparent");
    } else {
      setColor("lightgreen");
    }
  };

  return (
    <div className={classes.root}>
      <Paper style={{ margin: "0px" }} className={classes.paper}>
        <Table className={classes.table} size="small" style={table}>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.name}>
                <TableCellDefault
                  id="cell-title"
                  style={{ width: "25%!important" }}
                >
                  <input
                    style={{ border: "none", width: "20vw" }}
                    className="quote-input"
                    label=""
                    name={props.name[0]}
                    onChange={e => props.onChange(e)}
                    value={props.col1}
                  />
                </TableCellDefault>
                <TableCell defaultColor={props.defaultColor[1]}>
                  {" "}
                  <input
                    style={{ border: "none" }}
                    className="quote-input"
                    label=""
                    name={props.name[1]}
                    onChange={e => props.onChange(e)}
                    value={props.col2}
                  />
                </TableCell>
                <TableCell defaultColor={props.defaultColor[2]}>
                  <input
                    style={{ border: "none" }}
                    className="quote-input"
                    label=""
                    name={props.name[2]}
                    onChange={e => onChange(e)}
                    value={props.col3}
                  />
                </TableCell>
                <TableCell defaultColor={props.defaultColor[3]}>
                  <input
                    style={{ border: "none" }}
                    className="quote-input"
                    label=""
                    name={props.name[3]}
                    onChange={e => onChange(e)}
                    value={props.col4}
                  />
                </TableCell>
                <TableCell defaultColor={props.defaultColor[4]}>
                  <input
                    style={{ border: "none" }}
                    className="quote-input"
                    label=""
                    name={props.name[4]}
                    onChange={e => onChange(e)}
                    value={props.col5}
                  />
                </TableCell>
                <TableCell defaultColor={props.defaultColor[5]}>
                  <input
                    style={{ border: "none" }}
                    className="quote-input"
                    label=""
                    name={props.name[5]}
                    onChange={e => onChange(e)}
                    value={props.col6}
                  />
                </TableCell>
                <TableCell defaultColor={props.defaultColor[6]}>
                  <input
                    style={{ border: "none" }}
                    className="quote-input"
                    label=""
                    name={props.name[6]}
                    onChange={e => onChange(e)}
                    value={props.col7}
                  />
                </TableCell>
                <TableCell defaultColor={props.defaultColor[7]}>
                  <input
                    style={{ border: "none" }}
                    className="quote-input"
                    label=""
                    name={props.name[7]}
                    onChange={e => onChange(e)}
                    value={props.col8}
                  />
                </TableCell>
                <TableCell defaultColor={props.defaultColor[8]}>
                  <input
                    style={{ border: "none" }}
                    className="quote-input"
                    label=""
                    name={props.name[8]}
                    onChange={e => onChange(e)}
                    value={props.col9}
                  />
                </TableCell>
                <TableCell defaultColor={props.defaultColor[9]}>
                  <input
                    style={{ border: "none" }}
                    className="quote-input"
                    label=""
                    name={props.name[9]}
                    onChange={e => onChange(e)}
                    value={props.col10}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
