import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
//
import "../../css/tablecalc.css";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 650
  }
}));

// const onChange = e => {
//   e => props.onChange(e);
//   e => props.panelsChange(e.target.value);
// };

function createData(col1, col2, col3) {
  return { col1, col2, col3 };
}

export default function DenseTable(props) {
  const classes = useStyles();

  // React.useEffect(() => {

  //   props.panelsChange(props.item.solar_panels);
  // }, [props.item.solar_panels]);

  const rows = [
    createData(
      <span>
        Para atender a sua demanda de eletrecidade, o seu sistema gerador de
        energia solar fotovoltaica
        <br /> necessita de uma potência de:
      </span>,
      <input
        
        className="calcinput"
        label=""
        name="system_power"
        onChange={e => props.onChange(e)}
        value={props.item.system_power === null ? "" : props.item.system_power}
      />,
      "kWp"
    ),
    createData(
      "Terá uma quantidade de módulos FV:",
      <input
        
        className="calcinput"
        label=""
        name="solar_panels"
        onChange={e => {
          props.onChange(e);
          props.panelsChange(e.target.value);
        }}
        // onChange={e => {
        //   props.onChange(e);
        // }}
        value={props.item.solar_panels === null ? "" : props.item.solar_panels}
      />,
      "und"
    ),
    createData(
      "Área que irá ocupar no telhado será de :",
      parseFloat(props.item.solar_panels * 2 * 1.1).toFixed(2),
      "m2"
    ),
    createData(
      "A Unidade Consumidora deverá ter um Disjuntor maiior que:",
      <input
        
        className="calcinput"
        label=""
        name="disjuntor"
        onChange={e => props.onChange(e)}
        value={props.item.disjuntor === null ? "" : props.item.disjuntor}
      />,
      "A"
    ),
    createData(
      "A geração mensal de energia (média anual) de:",
      parseFloat(props.item.average_power_consumption).toFixed(2),
      "kWh/mês"
    )
  ];

  const table = {
    backgroundColor: "#fff"
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Table className={classes.table} size="small" style={table}>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.col1}>
                <TableCell component="th" scope="row">
                  {row.col1}
                </TableCell>
                <TableCell align="center">{row.col2}</TableCell>
                <TableCell align="center">{row.col3}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
